import _ from 'lodash';
import router from "@router";

export function dataLayerEvent(data) {
    const params = {
        event: data.event,
        eventCategory: data.eventCategory ? data.eventCategory.toLowerCase().replace(/ /g, "_") : null,
        eventLabel: data.eventLabel ? data.eventLabel.toLowerCase().replace(/ /g, "_") : null,
        eventAction: data.eventAction ?? null,
        screenName: router.currentRoute.path,
        eventContent: data.eventContent ?? null,
        eventContext: data.eventContext ?? null,
        buttonLocation: data.buttonLocation ?? null,
        actionGroup: data.actionGroup ?? 'interactions',
        projectName: 'geoeffect',
        touchPoint: 'web',
        userAuth: '0'
    };

    _.each(params, (item, key) => {
        if(!item) {
            delete params[key];
        }
    })

    try {
        window.dataLayer.push(params)
    } catch (e) {
        console.log('dataLayer error:');
        console.log(e);
    }
}